
import { Component, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";

@Component({
  components: {
    LogoSingle,
    SendStreetLightRequest: () =>
      import("@/components/request/SendStreetLightRequest.vue"),
    EndRequest: () => import("@/components/request/EndRequest.vue")
  }
})
export default class StreetLightRequest extends Vue {
  private mounted() {
    this.$store.dispatch("setStateToThree");
    this.$store.dispatch("cleanTicket");
    window.scroll(0, 0);
  }
}
